import $ from 'jquery';
import SwiperSlider from './SwiperSlider';
import productList from './productList';

export default {
    internalCounter: 0,
    /**
     * @param {string} wrapperSelector - wrapper selector
     */
    init(wrapperSelector) {
        $(wrapperSelector).each((i, wrapperSingle) => {
            this.internalCounter++;
            let $wrapperSingle = $(wrapperSingle);
            this.prepareSlider($wrapperSingle, this.internalCounter);
            this.initSlider(this.internalCounter);
        });
    },

    /**
     * @param {jQuery} wrapper
     * @param {number} i
     */
    prepareSlider(wrapper, i) {
        wrapper.find('.js-swiper-products-slider-next').attr('id', 'sw-next-'+i);
        wrapper.find('.js-swiper-products-slider-prev').attr('id', 'sw-prev-'+i);
        wrapper.find('.js-swiper-products-slider').attr('id', 'sw-container-'+i);
    },

    /**
     * @param {number} i
     */
    initSlider(i) {
        const swiperSlider = new SwiperSlider();
        const containerSelector = '#sw-container-'+i;
        swiperSlider.init(containerSelector, {
            navigation: {
                nextEl: '#sw-next-'+i,
                prevEl: '#sw-prev-'+i,
            },
            breakpoints: {
                1279: {
                    slidesPerView: 3,
                    spaceBetween: 40
                },
                991: {
                    slidesPerView: 2,
                    spaceBetween: 30
                },
                575: {
                    slidesPerView: 1,
                    spaceBetween: 20
                }
            }
        });
        productList.init(containerSelector);
    }
}


