$(document).on('change', '.js-type-checkbox', function () {
  const checked = $(this).is(':checked');

  $('.js-type-checkbox[value="' + $(this).val() + '"]')
    .prop('checked', checked);

  if ($('#js-quantity-step').data('is-reachable')) {
    submitForm($('#nextStep').val() - 1);
  } else {
    $('.js-style-radio[data-type-id="' + $(this).val() + '"]')
      .prop('disabled', !checked);
  }
});

$(document).on('click', '.js-style-icon', function () {
  const image = $(this);
  if (!image.hasClass('active')) {
    let data = new FormData();

    data.append(
      'id_product',
      $('#product_page_product_id').val()
    );
    data.append('id_type', $(this).data('id-type'));
    data.append('id_style', $(this).data('id-style'));

    $('.product-variants').find('input, select').each(function () {
      data.append(
        $(this).attr('name'),
        $(this).val()
      );
    });

    $.ajax({
      url: $('#js-style-step').data('action'),
      type: 'post',
      dataType: 'json',
      contentType: false,
      processData: false,
      data: data,
      success: function(response) {
        $('.images-container').replaceWith(response.html);
        $('.js-style-icon').removeClass('active');
        image.addClass('active');
      },
    });
  }
})

$(document).on('change', '.js-configuration-input', function (e) {

    if (!$(e.target).is(':checked')) {
        $(e.target).parent().prev('input').val('0');
    }

    submitForm($('#nextStep').val() - 1);
});

$(document).on('click', '.js-variants-radio-label:not(.disabled)', function (e) {
    $(e.target).closest('.js-variants-radio-items').find('.js-variants-radio-item').removeClass('active');
    $(e.currentTarget).parent().addClass('active');
});

$(document).on('change', '#js-toggle-customization', function () {
  submitForm();
})

$(document).on('click', '#js-configuration-update-cart, #js-submit-customization', function (e) {
  e.preventDefault();

  submitForm($('#nextStep').val(), $(this).data('action'));
});

function submitForm(submitStep = 0, url = null) {
  const wrapper = $('#js-configuration-wrapper');

  let data = new FormData($('#add-to-cart-or-refresh')[0]);
  data.append('submitStep', submitStep);

  data.append(
    'style_active',
    $('.js-style-icon .active').data('id-style')
  );

  if (!url) {
    url = wrapper.data('action');
  }

  $.ajax({
    url: url,
    type: 'post',
    contentType: false,
    processData: false,
    dataType: 'json',
    data: data,
    success: function(response) {
      if (response.redirect) {
        window.location.replace(response.redirect);
      } else if (response.message) {
        // response.success
          wrapper.replaceWith("<div class='alert alert-success'>" + response.message + "</div><div class='mb-5'><span class='btn btn-light' onClick=\"window.location.reload();\">Zamów ponownie</span></div>");
      } else {
        wrapper.html(response.html);
      }
    },
  });
}
