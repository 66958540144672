/* global $, prestashop */
$(document).ready(function () {
  $(document).on('change', '.js-massaddtocart-qty-input', function () {
    let value = parseInt($(this).val());
    let minValue = parseInt($(this).data('min-cart-qty'));
    let previousValue = parseInt($(this).data('previous-value'));

    if (value > 0 && value < minValue) {
      let newValue = value >= previousValue ? minValue : 0;

      $(this).val(newValue);
      $(this).data('previous-value', newValue);
    } else {
      $(this).data('previous-value', value);
    }

    // przy potwierdzeniu ilości?
    refreshSummary();
  });

  $(document).on('change', '#js-massaddtocart-hide-show-customization', function (e) {
    const checked = $(this).is(':checked');

    $('#js-massadtocart-submit-customization').prop('disabled', checked);
    $('#js-massadtocart-submit-cart-add').prop('disabled', !checked);

    if (checked) {
      $('#js-massaddtocart-customization').hide();
      $('#js-massaddtocart-cart-add').show();
    } else {
      $('#js-massaddtocart-customization').show();
      $('#js-massaddtocart-cart-add').hide();
    }
  });

  $(document).on('click', '#js-massadtocart-submit-customization', function (e) {
    e.preventDefault();

    $.ajax({
      url: $(this).data('action'),
      method: 'post',
      data: new FormData($(this).closest('form')[0]),
      dataType: 'json',
      contentType: false,
      processData: false,
      success: function (response) {
        if (response.success) {
          alert(response.message);
        } else {
          // klucz - być może nazwa pola
          $(response.errors).each('alert');
        }
      }
    });
  });

  $(document).on('click', '#js-massadtocart-submit-cart-add', function (e) {
    e.preventDefault();

    $.ajax({
      url: $(this).data('action'),
      method: 'post',
      data: $(this).closest('form').serialize(),
      dataType: 'json',
      success: function (response) {
        if (response.success) {
          prestashop.emit('updateCart', {
            reason: {
              cart: prestashop.cart,
              idProduct: response.id_product,
              idProductAttribute: response.id_product_attribute,
              linkAction: 'add-to-cart',
            }
          });
        } else {
          // ??
        }
      }
    });
  });
});

function refreshSummary() {
  const div = $('#js-massaddtocart-summary');

  $.ajax({
    url: div.data('action'),
    method: 'post',
    data: div.closest('form').serialize(),
    dataType: 'json',
    success: function (response) {
      if (response.success) {
        div.html(response.html);
      }
    }
  });
}
