import $ from "jquery";

export default class Newsletter {
    constructor() {
        $(document).on('submit', '.js-emailsubscription-form', (ev) => {
            ev.preventDefault();
            this.form = $(ev.currentTarget);
            this.send();
        });
        $(document).on('click keyup focusin', '.js-emailsubscription-email-input', (ev) => {
            let $inp = $(ev.currentTarget);
            this.form = $inp.closest('.js-emailsubscription-form');
            $('.js-consents:hidden', this.form).slideDown();
        });
    }

    send() {
        let data = this.serialize();
        data.ajax = 1;
        data.controller = 'subscription';
        data.fc = 'module';
        data.module = 'wnet_emailsubscription';
        data.submitNewsletter = 1;

        $.ajax({
            type: 'POST',
            url: '/index.php',
            data,
            success: (resp) => {
                resp = JSON.parse(resp);
                // console.log(resp);
                this.muteNotifications();
                if (typeof resp.nw_error !== "undefined") {
                    this.notify(resp.msg, resp.nw_error);
                }
            },
        });
    }

    notify(message, isError) {
        let $alert = $('.js-emailsubscription-form-response', this.form);
        $alert.html(message).show();
        if (isError) {
            $alert.addClass('alert-danger').removeClass('alert-success');
        } else {
            $alert.removeClass('alert-danger').addClass('alert-success');
        }
    }

    muteNotifications() {
        $('.js-emailsubscription-form-response', this.form).hide().removeClass('alert-danger').removeClass('alert-success');
    }

    serialize() {
        var o = {};
        var a = this.form.serializeArray();
        $.each(a, function () {
            if (o[this.name] !== undefined) {
                if (!o[this.name].push) {
                    o[this.name] = [o[this.name]];
                }
                o[this.name].push(this.value || '');
            } else {
                o[this.name] = this.value || '';
            }
        });
        return o;
    };

    validate() {

    }
}
