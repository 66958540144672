$(document).ready(function () {
  $(document).on('submit', '#product-question-form', function (e) {
    let agreements = $(this).find('.wnet_agreements-form');
    if (agreements.length > 0 && typeof validateAgreements === 'function') {
      validateAgreements(e, submitProductQuestion);
    } else {
      e.preventDefault();
      submitProductQuestion();
    }
  });
});

function submitProductQuestion() {
  let form = $('#product-question-form');

  $.ajax({
    url: form.attr('action'),
    method: 'post',
    data: form.serialize(),
    dataType: 'json',
    success: function (response) {
      if(response.success) {
        $('#product-questions-wrapper').html(response.message);
      } else {
        let alerts = $('#product-question-alerts');
        alerts.html('');
        response.errors.forEach(function (error) {
          alerts.append(error + '<br/>');
        });
      }
    }
  });
}
