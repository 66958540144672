import Swiper from 'swiper';

export default class SwiperSlider {
    /**
     * @param {SwiperOptions} defaultParams
     */
    constructor(defaultParams = {}) {
        this.defaultParams = defaultParams;
        /**
         * @type {Array.<Swiper>}
         */
        this.swipers = [];
    }

    /**
     * Initialize each Swiper matched by selector if it wasn't initialized before
     * @see getParams
     *
     * @param {String} selector
     * @param {SwiperOptions} params
     * @returns {Array.<Swiper>}
     */
    init(selector, params) {
        const swipers = [];
        $(selector).each((index, item) => {
            if (!$(item).hasClass('.swiper-container-initialized')) {
                swipers.push(
                    new Swiper(item, this.getParams(params, item))
                );
            }
        });

        this.swipers.push(...swipers);
        return swipers;
    }

    /**
     * Returns params passed to Swiper constructor
     * Params are combination of 3 level cascade hierarchy:
     * defaultParams - 1 level params passed to SwiperSlider object in constructor
     * initParams - 2 level params passed to init function, properties will override defaultParams
     * HTMLElement data-swiper attribute - overrides all other params as lowest possible level, except navigation parameter.
     * @param {Object} initParams
     * @param {HTMLElement} item
     * @returns {object}
     */
    getParams(initParams, item) {
        const htmlObjectParams = $(item).data('swiper') || {};
        if (!initParams || typeof initParams.navigation === 'undefined') {
            htmlObjectParams.navigation = {
                nextEl: $(item).parent().find('.swiper-button-next'),
                prevEl: $(item).parent().find('.swiper-button-prev'),
            }
        }

        return {
            ...this.defaultParams, ...initParams, ...htmlObjectParams,
            on: {
                init: function () {
                    if (this.isEnd && this.isBeginning) {
                        $(this.el).prev('.swiper-buttons').addClass('swiper-buttons--destroy');
                    }
                },
                resize: function () {
                    if (this.isEnd && this.isBeginning) {
                        $(this.el).prev('.swiper-buttons:not(".swiper-buttons--destroy")').addClass('swiper-buttons--destroy');
                    } else {
                        $(this.el).prev('.swiper-buttons').removeClass('swiper-buttons--destroy');
                    }
                },
            },
        };
    }
}
