export default {
  init(container = ''){
    $(container + ' .spinner__input').each((index, item) => {
      let $item = $(item),
          min,
          max;

      if ($item.get(0).hasAttribute('data-min')) {
        min = parseInt($item.attr('data-min'));
      } else if ($item.get(0).hasAttribute('min')) {
        min = parseInt($item.attr('min'));
      }

      if ($item.get(0).hasAttribute('data-max')) {
        max = parseInt($item.attr('data-max'));
      } else if ($item.get(0).hasAttribute('max')) {
        max = parseInt($item.attr('max'));
      }

      $item.TouchSpin({
        buttondown_class: 'btn js-touchspin',
        buttonup_class: 'btn js-touchspin',
        min,
        max,
      });
    });
  }
}
