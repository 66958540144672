import Wishlist from './Wishlist';

$(document).ready(function () {
  const wishlist = new Wishlist();
  prestashop.wishlist = wishlist;
  const wishlistModal = $('#wishlist-alert-modal');
  const wishlistModalText = $('#wishlist-alert-modal-text');

  $(document).on('click', '.wishlist-add', function (e) {
    e.preventDefault();
    var $el = $(this);
    wishlist
      .add($el.attr('href'))
      .then((response) => {
        $el.removeClass('wishlist-add');
        $el.addClass('wishlist-remove');
        wishlistModalText.html(response.message);
        wishlistModal.modal('show');
      });
  });

  $(document).on('click', '.wishlist-remove', function (e) {
    e.preventDefault();
    var $el = $(this);
    wishlist
      .remove($el.attr('href'))
      .then((response) => {
        $el.removeClass('wishlist-remove');
        $el.addClass('wishlist-add');
        wishlistModalText.html(response.message);
        wishlistModal.modal('show');
      });
  });

  $(document).on('click', '.js-wishlist-remove-with-miniature', function (e) {
    e.preventDefault();
    prestashop.emit('updateFacets', $(this).attr('href'));
  });

  wishlist.on('afterRequest', (response) => {
    wishlistModalText.html(response.message);
    wishlistModal.modal('show');
  })
});
