import EventEmitter from 'events';
import WishlistBridge from './WishlistBridge';

export default class Wishlist extends EventEmitter {

  constructor() {
    super();
    this.wishlistBridge = new WishlistBridge();
  }

  add(urlOrIdProduct, idProductAttribute = null){
    const url = this.prepareUrl(urlOrIdProduct, idProductAttribute);
    this.emit("beforeAdd", url);

    return new Promise((resolve, reject) => {
      this.wishlistBridge
        .add(url)
        .then((response) => {
          this.emit('request', response);
          if (response.success) {
            this.emit("beforeAddRequestSuccess", response);
            resolve(response);
            this.emit("afterAddRequestSuccess", response);
          } else {
            reject(response);
          }
      });
    });
  }

  remove(urlOrIdProduct, idProductAttribute = null){
    const url = this.prepareUrl(urlOrIdProduct, idProductAttribute);
    this.emit("beforeRemove", url);
    return new Promise((resolve, reject) => {
      this.wishlistBridge
        .remove(url)
        .then((response) => {
          this.emit('request', response);
          if (response.success) {
            this.emit("beforeRemoveRequestSuccess", response);
            resolve(response);
            this.emit("afterRemoveRequestSuccess", response);
          } else {
            reject(response);
          }
        });
    });
  }

  prepareUrl(urlOrIdProduct, idProductAttribute = null){
    return urlOrIdProduct;
  }

}
