import SwiperSlider from './SwiperSlider';

export default class ProductGallery{
  init(){
    this.initSliders();
  }

  initSliders(){
    this.swiperSlider = new SwiperSlider({
      loop: false,
    });

    this.thumbs = this.swiperSlider.init('.product-thumbs', {
      slidesPerView: 7,
      slideToClickedSlide: true,
      spaceBetween: 10,
      navigation: {
        nextEl: '.js-product-thumbs-next',
        prevEl: '.js-product-thumbs-prev',
      },
      breakpoints: {
        1279: {
          slidesPerView: 5,
        },
        991: {
          slidesPerView: 4
        },
        767: {
          slidesPerView: 5
        },
        420: {
          slidesPerView: 3
        }
      }
    });

    this.covers = this.swiperSlider.init('.products-imagescover', {
      navigation: {
        nextEl: '.js-product-cover-next',
        prevEl: '.js-product-cover-prev',
      },
    });

    if(this.covers.length && this.thumbs.length) {
      this.covers[0].on('slideChange', () => {
        this.thumbs[0].slideTo(this.covers[0].activeIndex, 200);

        $(this.thumbs[0].wrapperEl).find('.product-thumb').removeClass('selected');
        $(this.thumbs[0].slides[this.covers[0].activeIndex]).find('.product-thumb').addClass('selected');
      });

      this.thumbs[0].on('click', (el) => {
        this.covers[0].slideTo(this.thumbs[0].clickedIndex, 200);
      });

      this.thumbs[0].on('slideChange', () => {
        this.covers[0].slideTo(this.thumbs[0].activeIndex, 200);
      });
    }
  }
}
